<template>
    <section v-loading="loading">
        <div class="col mx-0 my-3">
            <div class="d-flex w-100">
                <div class="col">
                    <el-input v-model="buscar" class="w-100 input-radius-12" suffix-icon="icon-search" placeholder="Buscar" />
                </div>
            </div>
        </div>

        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 370px)">
            <div v-for="(data,d) in usersFiltered" :key="d" class="row mx-0">
                <div class="col-auto px-4 ml-3 f-500 f-15 text-gris">
                    {{ data.letra }}
                </div>
                <router-link
                v-for="(usuario, c) in data.items" 
                :key="c" class="col-12 pr-0 pl-2"
                :to="{
                    name:'configurar.usuarios.e-learning',
                    params:{id_user: usuario.id}
                }"
                >
                    <div class="row mx-0 py-2 a-center br-12 px-3 cr-pointer" :class="{ 'active-user': isSelected === usuario.id}" @click="selectedUser(usuario.id)">
                        <img :src="usuario.imagen" width="40" height="40" class="rounded-circle obj-cover border-gris" />
                        <p class="tres-puntos col text-gris">
                            {{ usuario.nombre }}
                        </p>
                        <div v-if="usuario.rol == 2" class="col-auto px-0">
                            <div class="bg-white border-edit tres-puntos f-18 px-3 br-12 text-gris">
                                Admin 
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import usuarios from '~/services/configurar/usuarios'
export default {
    name:'Elearning',
    data(){
        return {
            buscar: '',
            usuarios: [],
            loading: false,
            isSelected: false
        }
    },
    computed: {
        usersFiltered(){
            return this.usuarios
                .filter(el => el.items
                    .some(elm => 
                        this.buscar == '' ||
                        elm.nombre
                            .toLowerCase()
                            .includes(this.buscar.toLowerCase())
                    )
                )
        },
    },
    async mounted(){
        await this.listUsers()
    },
    methods: {
        async listUsers(){
            try {
                this.loading = true
                const { data } = await usuarios.listUsers()
                this.usuarios = data.data
                
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        selectedUser(id_user){
            this.isSelected =  id_user
        }
    },
}
</script>

<style lang="scss" scoped>
.border-edit{ border: 1px solid #DFDFDF !important; }
.active-user{ background-color:#F3F4F7; }
</style>